import { useState } from 'react'

import { fonts } from '@lumoslabs/lumosity-storybook'
import Tippy from '@tippyjs/react/headless'
import useTranslation from 'next-translate/useTranslation'
import styled from 'styled-components'

import BadgeImage from '~/components/BadgeImage'
import QuestionCircle from '~/images/question_circle.svg'

import BackgroundOverlay from '!~/images/GamePage/background_overlay.svg?inline'
import ProgressBar from './ui/ProgressBar'
import { CardShadowRadius } from './ui/sharedStyledComponents'

const { Body1, Body2Bold, Body3Caps, MicroText1, MicroText2SemiboldCaps, Subheading1Bold } = fonts

export const BADGE_GRADIENTS: Record<string, string> = {
  newcomer: 'kaleburstGradient',
  explorer: 'tealburstGradient',
  strategist: 'sunburstGradient',
  brainiac: 'sunburst43Gradient',
  mastermind: 'plumburstGradient',
}

export enum Badge {
  newcomer = 1,
  explorer,
  strategist,
  brainiac,
  mastermind,
}

export enum GoalStatus {
  Complete = 'Complete',
  Active = 'Active',
  Inactive = 'Inactive',
}

interface AchievementCardProps {
  gameSlug?: string
  badgeGoal?: Badge | string
  playCount?: number
  playCountGoal?: number
}

const AchievementCard: React.FC<AchievementCardProps> = ({
  gameSlug,
  badgeGoal = 'newcomer',
  playCount = 0,
  playCountGoal = 3,
}) => {
  const { t } = useTranslation('gameNamesAndDescriptions')
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const showTooltip = () => setTooltipVisible(true)
  const hideTooltip = () => setTooltipVisible(false)

  const allRanksAchieved = Badge[badgeGoal as keyof typeof Badge] === Badge.mastermind && playCount >= playCountGoal

  return (
    <AchievementCardContainer>
      <Tippy
        visible={tooltipVisible}
        onClickOutside={hideTooltip}
        placement='bottom-start'
        render={() => <AchievementTooltip />}
      >
        <StyledQuestionCircle onMouseOver={showTooltip} title='achievement-card-tooltip' />
      </Tippy>

      <AchievementCardHeader>
        <GameName>{t(`${gameSlug}.name`)}</GameName>
        <YourBadges>{t('game:badges.yourBadges')}</YourBadges>
      </AchievementCardHeader>
      <BadgeStepper badgeGoal={badgeGoal} playCount={playCount} playCountGoal={playCountGoal} />
      {allRanksAchieved ? (
        <AchievementStats badgeCount={5} playCount={playCount + 30} />
      ) : (
        <GoalProgress badgeGoal={badgeGoal} playCount={playCount} playCountGoal={playCountGoal} />
      )}
    </AchievementCardContainer>
  )
}

export const AchievementStats: React.FC<{ badgeCount: number; playCount: number }> = ({ badgeCount, playCount }) => {
  const { t } = useTranslation('game')

  return (
    <AchievementStatsContainer>
      <Subheading1Bold>{badgeCount}</Subheading1Bold>
      <BadgeStats>{t('stats.achievementBadges')}</BadgeStats>
      <VerticalDivider />
      <Subheading1Bold>{playCount}</Subheading1Bold>
      <BadgeStats>{t('stats.gamePlays')}</BadgeStats>
    </AchievementStatsContainer>
  )
}

export const getBadgeStatus = (badgeGoal: Badge, badge: Badge, goalComplete: boolean): GoalStatus => {
  if (badgeGoal < badge) {
    return GoalStatus.Inactive
  }
  if (badgeGoal === badge) {
    if (badge === Badge.mastermind && goalComplete) {
      return GoalStatus.Complete
    }
    return GoalStatus.Active
  }
  return GoalStatus.Complete
}

export const BadgeStepper: React.FC<{ badgeGoal: Badge | string; playCount: number; playCountGoal: number }> = ({
  badgeGoal = Badge.newcomer,
  playCount = 0,
  playCountGoal = 3,
}) => {
  const goalComplete = playCount >= playCountGoal
  const allRanksAchieved = goalComplete && Badge[badgeGoal as keyof typeof Badge] === Badge.mastermind
  const normalImageOrder = [1, 2, 3, 4, 5]
  const allRanksAchievedImageOrder = [2, 1, 4, 5, 3]
  const badgeOrder = allRanksAchieved ? allRanksAchievedImageOrder : normalImageOrder

  return (
    <BadgeCollectionContainer>
      {Object.values(Badge).map((badge) => {
        // skip enum members of type 'string'
        if (isNaN(Number(badge))) {
          return false
        }

        // badge should only be a number at this point
        const badgeAsNumber = +badge // coerce to number
        const status = getBadgeStatus(Badge[badgeGoal as keyof typeof Badge], badgeAsNumber, goalComplete)
        const order = badgeOrder[badgeAsNumber - 1]
        const scale = getScale(status, badgeAsNumber)

        return (
          <BadgeContainer key={`Badge-${badge}`} $scale={scale} $order={order}>
            <BadgeImage badgeStatus={status} badge={badgeAsNumber} />
          </BadgeContainer>
        )
      })}
    </BadgeCollectionContainer>
  )
}

export const getScale = (badgeStatus: GoalStatus, badge: Badge) => {
  if (badgeStatus === GoalStatus.Active) {
    return 1.3
  }
  if (badgeStatus === GoalStatus.Complete && badge === Badge.mastermind) {
    return 2
  }
  return 1
}

export const GoalProgress: React.FC<{ badgeGoal: string | Badge; playCount: number; playCountGoal: number }> = ({
  badgeGoal = 'newcomer',
  playCount = 0,
  playCountGoal = 3,
}) => {
  const { t } = useTranslation('game')
  const translatedBadgeGoal = t(`common:ranks.${badgeGoal}`)
  const progressPercentage = (playCount / playCountGoal) * 100

  return (
    <GoalProgressContainer>
      <Body1>{t('ranks.nextRankMilestone', { nextRank: translatedBadgeGoal, count: playCountGoal - playCount })}</Body1>
      <GoalProgressMeter>
        <ProgressBar progress={progressPercentage} color={BADGE_GRADIENTS[badgeGoal]} height={8} />
        <Body2Bold>{`${playCount}/${playCountGoal}`}</Body2Bold>
      </GoalProgressMeter>
    </GoalProgressContainer>
  )
}

export const AchievementTooltip = () => {
  const { t } = useTranslation('game')

  return (
    <>
      <AchievementTooltipCard>
        <MicroText2SemiboldCaps>{t('tooltip.achievementCard.title')}</MicroText2SemiboldCaps>
        <MicroText1>{t('tooltip.achievementCard.content')}</MicroText1>
      </AchievementTooltipCard>
    </>
  )
}

const AchievementCardContainer = styled(CardShadowRadius)`
  background-image: url(${BackgroundOverlay});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0px 36px;
  position: relative;
`

const AchievementCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

const AchievementStatsContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.coolGray82};
  border-radius: 100px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 4px 24px;
`

const AchievementTooltipCard = styled(CardShadowRadius)`
  max-width: 240px;
  padding: 10px;
  color: ${({ theme }) => theme.colors.oatBase};
  background: ${({ theme }) => theme.colors.moonshineGradient};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0px 2px 8px 2px rgba(14, 35, 55, 0.2);
`

const BadgeCollectionContainer = styled.div`
  font-size: 3.5em;
  display: flex;
  gap: 5%;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const BadgeContainer = styled.div<{ $scale: number; $order: number | string }>`
  display: flex;
  order: ${({ $order }) => $order};
  padding: ${({ $scale }) => ($scale > 1 ? `${$scale * 8}px` : 0)} 0;
  > svg {
    font-size: ${({ $scale }) => ($scale > 1 ? `${$scale * 1}em` : '1em')};
  }
`

const BadgeStats = styled(Body1)`
  color: ${({ theme }) => theme.colors.coolGray44};
  margin: 4px 0;
`

const GoalProgressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const GoalProgressMeter = styled.div`
  margin-top: 1em;
  display: flex;
  width: calc(100% - 64px);
  gap: 16px;
  color: ${({ theme }) => theme.colors.inkBase};
  > div {
    width: 100%;
  }
`

const GameName = styled(Body3Caps)`
  color: ${({ theme }) => theme.colors.coolGray44};
`

const StyledQuestionCircle = styled(QuestionCircle)`
  position: absolute;
  top: 17px;
  right: 17px;
  font-size: 1.15em;
`

const VerticalDivider = styled.div`
  height: 75%;
  width: 0;
  margin: 0 8px;
  border: 1px solid ${({ theme }) => theme.colors.coolGray82};
`

const YourBadges = styled(Subheading1Bold)`
  color: ${({ theme }) => theme.colors.inkBase};
`

export default AchievementCard
