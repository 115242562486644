import Rank1InactiveBadge from './rank1_inactive.svg'
import Rank2InactiveBadge from './rank2_inactive.svg'
import Rank3InactiveBadge from './rank3_inactive.svg'
import Rank4InactiveBadge from './rank4_inactive.svg'
import Rank5InactiveBadge from './rank5_inactive.svg'

export default {
  rank1: Rank1InactiveBadge,
  rank2: Rank2InactiveBadge,
  rank3: Rank3InactiveBadge,
  rank4: Rank4InactiveBadge,
  rank5: Rank5InactiveBadge,
} as Record<
  string,
  React.VFC<
    React.SVGProps<SVGSVGElement> & {
      title: string
      onMouseOver?: () => void
    }
  >
>
