import Rank1ActiveBadge from './rank1_active.svg'
import Rank2ActiveBadge from './rank2_active.svg'
import Rank3ActiveBadge from './rank3_active.svg'
import Rank4ActiveBadge from './rank4_active.svg'
import Rank5ActiveBadge from './rank5_active.svg'

export default {
  rank1: Rank1ActiveBadge,
  rank2: Rank2ActiveBadge,
  rank3: Rank3ActiveBadge,
  rank4: Rank4ActiveBadge,
  rank5: Rank5ActiveBadge,
} as Record<
  string,
  React.VFC<
    React.SVGProps<SVGSVGElement> & {
      title: string
      onMouseOver?: () => void
    }
  >
>
