import Rank0CompleteBadge from './rank0_complete.svg'
import Rank1CompleteBadge from './rank1_complete.svg'
import Rank2CompleteBadge from './rank2_complete.svg'
import Rank3CompleteBadge from './rank3_complete.svg'
import Rank4CompleteBadge from './rank4_complete.svg'
import Rank5CompleteBadge from './rank5_complete.svg'

export default {
  rank0: Rank0CompleteBadge,
  rank1: Rank1CompleteBadge,
  rank2: Rank2CompleteBadge,
  rank3: Rank3CompleteBadge,
  rank4: Rank4CompleteBadge,
  rank5: Rank5CompleteBadge,
} as Record<
  string,
  React.VFC<
    React.SVGProps<SVGSVGElement> & {
      title: string
      onMouseOver?: () => void
    }
  >
>
