import Complete from './Complete'
import Active from './Active'
import Inactive from './Inactive'
import { GoalStatus } from '~/components/AchievementCard'

export default {
  Complete,
  Active,
  Inactive,
} as Record<
  GoalStatus,
  Record<
    string,
    React.VFC<
      React.SVGProps<SVGSVGElement> & {
        title: string
        onMouseOver?: () => void
      }
    >
  >
>
