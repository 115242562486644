import BADGE_IMAGES from '~/images/Badges'

import { Badge, GoalStatus } from './AchievementCard'

const BadgeImage = ({ badge, badgeStatus }: { badge: Badge; badgeStatus: GoalStatus }) => {
  const BadgeImage = BADGE_IMAGES[badgeStatus][`rank${badge}`]

  return <BadgeImage title={`${badgeStatus}-badge-rank-${badge}`} />
}

export default BadgeImage
